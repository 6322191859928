export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品编号",
    dataIndex: "number",
    width: 120,
    sorter: true,
  },
  {
    title: "产品名称",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "英文名称",
    dataIndex: "english_name",
  },
  {
    title: "产品条码",
    dataIndex: "barcode",
    width: 120,
  },
  {
    title: "包装规格",
    dataIndex: "spec",
    width: 80,
  },
  {
    title: "单位",
    width: 60,
    dataIndex: "unit",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
